<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ data.name ? data.name : "Новый" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <a-form-model2
        class="mt-1"
        v-model="data"
        :model="model"
        :modelForm="modelForm"
        :errors="errors"
        :config="{ dense: true, hideDetails: 'auto' }"
        @validate="validate($event)"
      />

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
    <test-dialog v-if="id" v-model="showTests" :id="id" @save="saveTest($event)" :data="data" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, genModel } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, genModel],
  components: {
    testDialog: () => import("@/views/dialogs/infoTestDialog"),
  },
  props: {
    value: Boolean,
    id: Number,
    parent_id: Number,
  },
  data() {
    return {
      modelForm: null,
      m: this.$store.getters["config/get"].models.info.group,
      api: "/mechti/information_group",
      removeDialogShow: false,
      showTests: false,
    };
  },

  computed: {
    config() {
      return this.m.config;
    },
    model() {
      let parent = this.data?.is_parent ? 1 : 0;
      return this.calcModelConfig(this.config[parent]);
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    afterFetchData() {
      console.log("afterFetchData", this.parent_id);
      if (!this.id) this.data.parent_id = this.parent_id || 0;
    },
    saveTest(e) {
      let data = { id: this.id, test_data: e };
      this.post(data, this.api);
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
